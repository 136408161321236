import React from 'react';
import CustomModal from './modal';
import Maps from './map';
import axios from 'axios';
import { API_CONFIG } from '../../../common/constant';
import { getToken } from '../../../common/authService';

const getFullDate = (date) => {
    const startedDate = new Date(date);
    const dd = String(startedDate.getDate()).padStart(2, '0');
    const mm = String(startedDate.getMonth() + 1).padStart(2, '0');
    const yyyy = startedDate.getFullYear();
    return `${dd}/${mm}/${yyyy}`;
}

const getTime = (date) => {
    const d = new Date(date);
    const n = d.toLocaleString([], {
        hour: '2-digit',
        minute: '2-digit'
    });
    return n;
}
 

const HistoryCard = props => {
    const [isOpen, changeIsOpen] = React.useState(false);
    const [traversalDetail, changeTraversalDetail] = React.useState({});
    const date = getFullDate(props.startedTime);
    const time = getTime(props.startedTime);
    const onClickHistoryCard = () => {

        changeIsOpen(true);
        axios.get(`${API_CONFIG.traversal}/${props.id}`, { headers: { accesstoken: getToken() } }).then(response => {
          
            console.log(response.data)
            changeTraversalDetail(response.data);
     
        }).catch(error => console.log(error));
    }
    return (
        <>
            <div className='history-card' onClick={onClickHistoryCard}>
    
                <div className='info-item'>
                    <p className='mb-0'>{props.centerName}</p>
                </div>
                {props.routeName && 
                    <div className='info-item'>
                        <p className='mb-0'>{props.routeName}</p>
                    </div>
                }
                <div className='info-item'>
                  <p  className='mb-0'>{props.status}</p>
                </div>
                <div className='info-item'>
                    <p className='mb-0'>{date} {time}</p>
                </div>
            </div>
            {isOpen &&
                <CustomModal
                    bsSize='lg'
                    show={true}
                    handleClose={() => changeIsOpen(false)}
                >
                    {traversalDetail.traversal &&
                        <Maps path={(traversalDetail.traversal || []).map(t => ({ lat: t.latitude, lng: t.longitude }))} />}
                </CustomModal>
            }
        </>
    )
}

export default HistoryCard;