import React, { Component } from 'react';
import { connect } from 'react-redux';
import Signup from '../component/signup';

import * as LoginActions from '../store/auth.action';

class SignupContainer extends Component {
    componentDidUpdate = () => {
        if (this.props.auth) {
            this.props.history.push('/');
        }
    }

    render() {
        return (
            <Signup
                loading={this.props.authLoading}
                signup={this.props.signup}
            />
        )
    }
}

const mapStateToProps = state => ({
    ...state.common
});

const mapDispatchToProps = dispatch => ({
    signup: (data) => LoginActions.signup(data, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(SignupContainer);