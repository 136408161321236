import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { Link } from 'react-router-dom';

import InputComponent from '../../../common/form/input';

const LoginForm = props => {
    return (
        <Formik
            initialValues={{ email: "", password: "" }}
            onSubmit={props.login}
            validationSchema={ValidationSchema}
        >
            {({handleSubmit, values}) => (
                <form onSubmit={handleSubmit}>
                    <div className='form-item'>
                        <Field 
                            name='email'
                            type='text'
                            value={values.email}
                        >
                            {props => (<InputComponent 
                                    {...props}
                                    name='email'
                                    type='text'
                                    placeholder="Email"
                              
                                />)}
                        </Field>
                        <ErrorMessage component='span' className='error' name='email' />
                    </div>
                    <div className='form-item'>
                        <Field 
                            name='password'
                            type='password'
                            value={values.password}
                        >
                            {props => (<InputComponent 
                                name='password'
                                {...props}
                                type='password'
                                placeholder="Password"
                                
                            />)}
                        </Field>
                        <ErrorMessage component='span' className='error' name='password' />
                        <div className='text-right mt-3'>
                            <Link to='/forgot-password'>
                                Forgot Password?
                            </Link>
                        </div>
                    </div>
                    <button 
                        disabled={props.loading}
                        type='submit'
                        className='button button--primary button-full-width'
                    >
                      Login
                    </button>
                </form>
            )}
        </Formik>
    );
}

const ValidationSchema = Yup.object({
    email: Yup.string().email('Please enter valid email id').required('Please enter email'),
    password: Yup.string().required('Please enter password')
})

export default LoginForm;