import React from 'react'
import StripeCheckout from 'react-stripe-checkout';

const fromDollarToCent = amount => Math.round(amount * 100);

const Checkout = ({ name, email, description, amount, currency, stripePublishableKey, onSubmitPayment, paymentDoneStatus, label, productId, couponCode }) =>
  <StripeCheckout
    label={label}
    name={name}
    email={email}
    description={description}
    image="https://www.testroutes.co.uk/wp-content/uploads/2020/05/TestRoutesLogo.png"
    amount={fromDollarToCent(amount)}
    token={onSubmitPayment(productId, couponCode)}
    currency={currency}
    stripeKey={stripePublishableKey}
    zipCode
    allowRememberMe
  />

export default Checkout;
