import React from 'react';
import { withGoogleMap, withScriptjs, GoogleMap, Polyline } from 'react-google-maps';
import * as constants from '../../../common/constant';

const Map = (props) => {
    return (
        <GoogleMap
            defaultZoom={16}
            defaultCenter={props.path[0]}
        >
            <Polyline path={props.path} options={{ strokeColor: "#FF0000 " }} />
        </GoogleMap>
    );
}

const MapComponent = withScriptjs(withGoogleMap(Map));

const Maps = (props) => {
    return (
        <MapComponent
            {...props}
            googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${constants.GOOGLE_MAP_API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div style={{ height: 'calc(100vh - 150px)', width: '100%' }} />}
            mapElement={<div style={{ height: '100%' }} />}
        />
    )
};

export default Maps;