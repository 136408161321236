import React, {Component} from 'react';
import {connect} from 'react-redux';
import axios from 'axios';

import Home from '../component/home';
import {createAction} from '../../../common/utility';
import {API_CONFIG} from '../../../common/constant';
import {getToken, getAuthData, setAuthData} from '../../../common/authService';

import successImage from '../../../assets/images/check.jpeg';
import faildImage from '../../../assets/images/close.jpeg';
// import Checkout from '../../payment/component/checkout';
import Logo from '../../../assets/images/logo.png';
import userIcon from '../../../assets/images/user-icon.png';
import homeIcon from '../../../assets/images/home-icon.png';
import keyIcon from '../../../assets/images/key-icon.png';
import logoutIcon from '../../../assets/images/logout-icon.png';
import ChangePassword from '../component/changePassword';
import Footer from '../../../common/component/footer';
import * as LoginActions from '../../auth/store/auth.action';
import VerifyEmail from '../component/verifyEmail';
import SubscriptionPayment from "../component/subscriptionPayment";
import SubscriptionPlan from '../component/subcriptionPlan';
import { Link } from "react-router-dom";

class HomeContainer extends Component {
    state = {
        tab: '',
        testData: [],
        loading: false,
        isScrolling: false,
        limit: 10,
        page: 1,
        total: 0,
        totalPage: 0,
        paymentSuccess: false,
        paymentError: false,
        isPaymentLoading: false,
        subscription: false,
        isDropdownActive: false,
        paymentErrorMessage: '',
        showChangePassword: false,
        currentPlan: '',
        paymentModalOpen: false,
        history: false,
        purchasePlans: [],
        allPlans: [],
    }

    componentDidMount = () => {
        let previousURL = document.referrer;
        previousURL = previousURL.split("?")[0];

        const origin = window.location.origin;

        const paymentUrl = origin + "/subscription";
        if (previousURL === paymentUrl) {
            this.setState({subscription: true})
        } else {
            this.setState({history: true})
        }
        const authData = getAuthData();
        if (authData.profile.isVerified) {
            this.onComponentMount();
        }
    }

    componentWillUnmount = () => {
        window.removeEventListener('scroll', this.handleScroll);
    }

    render() {
        const authData = getAuthData();
        if (!authData.profile.isVerified) {
            return (
                <VerifyEmail
                    loading={this.state.loading}
                    onSubmit={this.verifyEmail}
                    resendCode={this.resendCode}
                    signUp={this.onSignUpClick}
                />
            );
        }

        return (
            <section className='home-section' onClick={() => this.setState({isDropdownActive: false})}>
                <div className='container'>
                    <div className='gray-wrapper'>
                    <header className='header  d-flex align-items-center justify-content-between'>
                        <div className='logo' onClick={() => this.setState({showChangePassword: false})}>
                           <Link to="/"> <img src={Logo} alt='logo'/> </Link>
                        </div>
                        <div className='link-wrapper d-flex align-items-center'>
                            {/* {!!this.state.purchasePlans.length &&
                            <div className='mr-2'>
                                <div className='profile-dropdown' onClick={this.togglePaymentDropdown}>
                                    <button className="payment-btn">
                                        <span>Subscribe</span>
                                    </button>
                                </div>
                            </div>
                            } */}
                            <div className='subcription-details'>
                              <span>
                              <img src={homeIcon} alt="Home Icon" />
                              Subscription Details:<b>{authData.profile.subscriptionType}</b>
                             
                              </span>
                            </div>
                            <div className='profile-dropdown' onClick={event => this.toggleLogoutDropDown(event)}>
                                <span className='user-name'>
                                    <img src={userIcon} alt="User Icon" />
                                    {authData.profile.fullName}
                                </span>
                                {this.state.isDropdownActive && <ul>
                                    <li onClick={event => this.toggleChangePassword(event)}>
                                    <img src={keyIcon} alt="Key Icon" />
                                        Change Password
                                    </li>
                                    <li onClick={event => this.onLogout(event)}>
                                    <img src={logoutIcon} alt="Logout Icon" />
                                        Log out
                                    </li>
                                </ul>}
                            </div>
                        </div>
                    </header>
                    {this.state.showChangePassword &&
                    <ChangePassword loading={this.state.actionLoading} onSubmit={this.handleChangePassword}/>}
                    {this.state.history &&
                    <Home
                        {...this.state}
                        onTabChange={this.handleTabChange}
                        toggleDropDown={this.toggleLogoutDropDown}
                        onLogout={this.onLogout}
                    />}
                    {this.state.subscription &&
                    <SubscriptionPayment
                        purchasePlans={this.state.purchasePlans}
                        allPlans={this.state.allPlans}
                        authData={authData}
                        applyCouponCode={this.applyCouponCode}
                        handlePayment={this.handlePayment}
                    />}
                    </div>
                    <Footer/>
                </div>
                {(this.state.paymentSuccess || this.state.paymentError || this.state.isPaymentLoading) &&
                <div className='payment-status-modal d-flex align-items-center justify-content-center'>
                    <div className='status-modal-content d-flex flex-column align-items-center'>
                        {!this.state.paymentError && <h6 className='mt-0 mb-3 font-bold'>
                            {this.state.paymentSuccess ? 'Your Payment is recived' : 'Please wait payment is under process'}
                        </h6>}
                        {this.state.paymentError && <h6 className='mt-0 mb-3 font-bold text-primary'>
                            {this.state.paymentErrorMessage}
                        </h6>}
                        {this.state.isPaymentLoading && <svg className="spinner" viewBox="0 0 50 50">
                            <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
                        </svg>}
                        {this.state.paymentSuccess && <img alt='success' src={successImage}/>}
                        {this.state.paymentError && <img alt='faild' src={faildImage}/>}
                    </div>
                </div>}
            </section>
        )
    }

    onSignUpClick = (event) => {
        this.onLogout(event);
        this.props.history.push('/signup');
    }

    verifyEmail = (data) => {
        this.setState({loading: true});
        const headers = {accesstoken: getToken()};
        axios.post(API_CONFIG.verifyEmail, data, {headers}).then(response => {
            console.log(response);
            const authData = getAuthData();
            authData.profile.isVerified = true;
            setAuthData(authData);
            this.setState({loading: false});
            this.onComponentMount();
        }).catch(error => {
            let message = error.message;
            if (error.response) {
                message = error.response.data.message;
            }
            this.props.addNotification({type: 'error', message: message});
            this.setState({loading: false});
        });
    }

    resendCode = () => {
        const headers = {accesstoken: getToken()};
        axios.post(`${API_CONFIG.verifyEmail}/resend`, {}, {headers}).then(response => {
            this.props.addNotification({type: 'success', message: response.data.message});
        }).catch(error => {
            let message = error.message;
            if (error.response) {
                message = error.response.data.message;
            }
            this.props.addNotification({type: 'error', message: message});
        });
    }

    onComponentMount = () => {
        this.fetchHistory({limit: this.state.limit, page: this.state.page});
        this.fetchProfile().then(() => {
            this.fetchPlans();
        });
        window.addEventListener("scroll", this.handleScroll);
    }

    togglePaymentDropdown = () => {
        this.setState(
            {
                subscription: !this.state.subscription,
                showChangePassword: false,
                isDropdownActive: false,
                paymentModalOpen: false,
                history: this.state.subscription
            })
    }

    toggleLogoutDropDown = (event) => {
        event.stopPropagation();
        this.setState({isDropdownActive: !this.state.isDropdownActive});
    }

    onLogout = (event) => {
        event.stopPropagation();
        LoginActions.logout();
        localStorage.removeItem('authData');
        this.props.logout();
    }

    toggleChangePassword = (event) => {
        event.stopPropagation();
        this.setState({
            showChangePassword: true,
            isDropdownActive: false,
            subscription: false,
            paymentModalOpen: false,
            history: false
        })
    }
    toggleHistory = () => {
        this.setState({
            showChangePassword: false,
            isDropdownActive: false,
            subscription: false,
            paymentModalOpen: false,
            history: true
        })
    }

    handleScroll = () => {
        if (!this.state.loading && !this.state.isScrolling && this.state.totalPage !== this.state.page && this.state.testData.length > 0) {
            let lastLi = document.querySelector(".history-card-wrapper > .history-card:last-child");
            const lastLiOffset = lastLi.offsetTop + lastLi.clientHeight;
            const pageOffset = window.pageYOffset + window.innerHeight;

            if (this.state.testData.length >= 10 && pageOffset > lastLiOffset) {
                const params = {
                    limit: this.state.limit,
                    page: this.state.page + 1
                }
                this.fetchHistory(params, true);
                this.setState({
                    page: this.state.page + 1
                });
            }
        }
    };

    handleTabChange = (tab) => {
        const params = {
            limit: this.state.limit,
            page: 1
        }
        this.setState({tab, testData: []}, () => this.fetchHistory(params));
    }

    fetchHistory = (data, isScrolling = false) => {
        const headers = {
            accesstoken: getToken()
        }
        this.setState({loading: !isScrolling, isScrolling: isScrolling});
        const url = this.state.tab ? `${API_CONFIG.traversal}/${this.state.tab}` : API_CONFIG.traversal;
        axios.get(url, {params: data, headers}).then(response => {
            if (response.status === 200) {
                this.setState({
                    loading: false,
                    testData: [...this.state.testData, ...response.data.traversals],
                    isScrolling: false,
                    totalPage: Math.ceil((response.data.total + this.state.limit - 1) / this.state.limit - 1),
                    total: response.data.total
                });
            }
        }).catch(error => {
            let message = error.message;
            if (error.response) {
                message = error.response.data.message;
            }
            this.props.addNotification({type: 'error', message: message});
            this.setState({loading: false, isScrolling: false});
        });
    }

    handleChangePassword = (data) => {
        const headers = {
            accesstoken: getToken()
        }
        const params = {
            oldPassword: data.oldPassword,
            newPassword: data.newPassword
        }
        this.setState({actionLoading: true});
        return axios.post(API_CONFIG.changePassword, params, {headers})
            .then((response) => {
                this.props.addNotification({type: 'success', message: response.data.message});
                this.setState({actionLoading: false, showChangePassword: false});
            })
            .catch((error) => {
                let message = error.message;
                console.log(error.response);

                if (error.response) {
                    message = error.response.data.message;
                }
                this.props.addNotification({type: 'error', message: message});
                this.setState({actionLoading: false});
            });
    }

    handlePayment = (productId) => (stripeToken) => {
        const headers = {
            accesstoken: getToken()
        }
        this.setState({isPaymentLoading: true})
        return axios.post(API_CONFIG.payment, {stripeToken: stripeToken.id, productId}, {headers})
            .then(() => {
                const authData = getAuthData();
                this.props.addNotification({type: 'success', message: "Subscription payment completed successfully."});

                setAuthData({
                    ...authData,
                    profile: {...authData.profile, userType: 'paid'},
                    config: {...authData.config, subscription_status: 'succeeded'}
                });

                this.setState({
                    subscription: true,
                    isPaymentLoading: false,
                    paymentSuccess: true,
                    currentPlan: productId
                });
                this.filterPlans(this.state.allPlans);
                this.hidePaymentModal();
                setTimeout(() => {
                    this.toggleHistory();
                }, 2500)
            })
            .catch((error) => {
                let message = error.message;
                if (error.response) {
                    message = error.response.data.message;
                }
                this.setState({
                    isPaymentLoading: false,
                    paymentError: true,
                    paymentErrorMessage: message,
                    paymentDoneStatus: false
                });
                this.hidePaymentModal();
            });
    }

    fetchProfile = () => {
        return new Promise(resolve => {
            axios.get(API_CONFIG.profile, {headers: {accesstoken: getToken()}}).then(response => {
                setAuthData({...getAuthData(), ...response.data});
                this.setState({currentPlan: response.data.profile.currentPlan}, resolve);
            }).catch(error => {
                console.log(error);
                resolve();
            });
        });
    }

    filterPlans = (allPlans) => {
        const purchasePlans = allPlans.map(product => {
            if (this.state.currentPlan === '') {
                if (product.id.includes('upgrade')) {
                    return null;
                }
            } else if (this.state.currentPlan.includes('basic')) {
                if (!product.id.includes('upgrade')) {
                    return null;
                }
            } else if (this.state.currentPlan.includes('upgrade') || this.state.currentPlan.includes('advanced')) {
                return null;
            }
            return product;
        }).filter(Boolean);
        this.setState({purchasePlans, allPlans});
    }

    fetchPlans = () => {
        if (!this.state.currentPlan.includes('advanced')) {
            axios.get(API_CONFIG.purchasePlans, {headers: {accesstoken: getToken()}}).then(response => {
                this.filterPlans(response.data.products);
            }).catch(error => console.log(error));
        }
    }

    applyCouponCode = (couponCode) => {
        axios.post(API_CONFIG.couponCodeApply, {
            couponCode: couponCode
        }, {headers: {accesstoken: getToken()}}).then(response => {
            this.filterPlans(response.data.products);
        }).catch(error => {
            this.props.addNotification({type: 'error', message: "Coupon code invalid or expired"});
            console.log(error)
        });
    }

    hidePaymentModal = () => {
        setTimeout(() => {
            this.setState({
                paymentSuccess: false,
                paymentErrorMessage: '',
                paymentError: false
            })
        }, 2000)
    }
}

const mapDispatchToProps = dispatch => ({
    addNotification: (data) => dispatch(createAction('ADD_NOTIFICATION', data)),
    logout: () => dispatch(createAction('LOGOUT'))
});

export default connect(null, mapDispatchToProps)(HomeContainer);