import CryptoJS from 'crypto-js';
const key = 'RdOMhNfytTqnrwTisZFsUcrWbCCDccdhdDkbFIvr';

const setAuthData = (data) => {
    const encData = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString();
    localStorage.setItem('authData', encData);
}

const getAuthData = () => {
    const authData = localStorage.getItem('authData');
    const decryptedData  = CryptoJS.AES.decrypt(authData, key);
    const data = decryptedData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(data);
}

const getToken = () => {
    const authData = localStorage.getItem('authData');
    const decryptedData  = CryptoJS.AES.decrypt(authData, key);
    const data = decryptedData.toString(CryptoJS.enc.Utf8);
    return JSON.parse(data).accessToken;
}

const checkLogin = () => {
    return !!localStorage.getItem('authData');
}

export {
    setAuthData,
    getAuthData,
    checkLogin,
    getToken
}
