import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../../assets/images/logo.png';

import LoginForm from './loginForm';
import victory from '../../../assets/images/victory.png';
import Footer from '../../../common/component/footer';

const Login = props => {
    return (
        

        <section className='login-page'>
            <div className='container-fluid'>
                <div className='row'>
                    <div className='col-xl-6 col-lg-6  col-md-12 login-col'>
                       <div className='login-wrapper'>
                        <header className='header'>
                                <div className='logo'>
                                    <Link to='/login'>
                                        <img src={Logo} alt='logo' />
                                    </Link>
                                </div>
                            </header>
                            <div className='section-heading'>
                                <h3 className=' mt-0'>Login   <img src={victory} alt='Victory' /></h3>
                                <p className='mb-0 mt-0'>Sign in to continue!</p>
                            </div>
                            <div className='login-box'>
                                <LoginForm
                                    {...props}
                                />
                            </div>
                            <div className='no-account-text text-center'>
                                <span>Don't have an account? <Link to='/signup' className='text-primary'>Sign Up</Link></span>
                            </div>
                       </div>
                    </div>
                    <div className='col-xl-6 col-lg-6  col-md-12 cover-col'>
                        <div className='we-cover'>
                            <div className='left'>
                                <h4>
                                    <span>We cover </span>Postal areas driving <b><span>Test</span>Routes.</b>
                                </h4>
                            </div>
                            <div className='right'>
                            <div className='stats'><div id="number1">98</div><sup>%</sup></div>
                            </div>
                        </div>
                        <div className='dont-have-account'>
                            <span>Don't have an account? <a class="text-primary" href="/signup">Sign Up</a></span>
                        </div>
                        <div className='claim-text'>
                            <p>The number <b>01</b> app for all learners and driving instructors</p>
                            <h4>Claim your 7 Days <span>Free Trial</span> Now</h4>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </section>


    )
}

export default Login;