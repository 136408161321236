import React, {Component} from 'react';
import axios from "axios";
import {API_CONFIG} from "../../../common/constant";
import {setAuthData} from "../../../common/authService";

class SubscriptionContainer extends Component {
    componentDidMount = () => {
        const query = new URLSearchParams(this.props.location.search);
        const token = query.get('token')
        const host = window.location.host;

        axios.get(API_CONFIG.subscriptionProfile, {headers: {accesstoken: token}}).then(response => {
            let authData = response.data;
            authData.accessToken = token;
            setAuthData(authData);
            let subscriptionPaymentLink = window.host + "/subscription/payment"
            window.location.replace(subscriptionPaymentLink);
        }).catch(() => {
            window.location.replace(host);
        });
    }


    render() {
        return (
            <div/>
        )
    }
}


export default SubscriptionContainer;