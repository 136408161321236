import React from 'react';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';


import InputComponent from '../../../common/form/input';


const SignupForm = props => {
    return (
        <Formik
            initialValues={{ fullName: '', email: '', password: '' }}
            onSubmit={props.signup}
            validationSchema={ValidationSchema}
        >
            {({ handleSubmit, values }) => (
                <form onSubmit={handleSubmit} className='signup-form d-flex justify-content-between flex-column'>
                  
                    
                       
                        <div className='form-item'>
                            <Field 
                                name='fullName'
                                type='text'
                                value={values.fullName}
                            >
                                {props => (<InputComponent 
                                        {...props}
                                        name='fullName'
                                        type='text'
                                        placeholder='Full Name'
                                    />)}
                            </Field>
                            <ErrorMessage component='span' className='error' name='fullName' />
                        </div>
                        <div className='form-item'>
                            <Field 
                                name='email'
                                type='text'
                                value={values.email}
                            >
                                {props => (<InputComponent 
                                        {...props}
                                        name='email'
                                        type='text'
                                        placeholder='E-mail'
                                    />)}
                            </Field>
                            <ErrorMessage component='span' className='error' name='email' />
                        </div>
                        {/* <div className='form-item'>
                            <Field 
                                name='contactNumber'
                                type='text'
                                value={values.contactNumber}
                            >
                                {props => (<InputComponent 
                                        {...props}
                                        name='contactNumber'
                                        type='text'
                                        placeholder='Contact Number'
                                    />)}
                            </Field>
                            <ErrorMessage component='span' className='error' name='contactNumber' />
                        </div> */}
                        <div className='form-item'>
                            <Field 
                                name='password'
                                type='password'
                                value={values.password}
                            >
                                {props => (<InputComponent 
                                    name='password'
                                    {...props}
                                    type='password'
                                    placeholder='Password'
                                />)}
                            </Field>
                            <ErrorMessage component='span' className='error' name='password' />
                        </div>
                        <button 
                        disabled={props.loading}
                        type='submit'
                        className='button button--primary button-full-width'
                    >
                      Sign up
                    </button>
                    
                </form>
            )}
        </Formik>
    );
}

const ValidationSchema = Yup.object({
    fullName: Yup.string().required('Please eneter Full Name'),
    email: Yup.string().email('Please enter valid email id').required('Please enter email'),
    password: Yup.string().required('Please enter password')
})

export default SignupForm;