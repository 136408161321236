import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from 'react-redux';

import 'bootstrap/dist/css/bootstrap.min.css';

import './assets/css/app.scss';

import Login from './components/auth/container/login';
import Signup from './components/auth/container/signup';
import Subscription from './components/auth/container/subscription';
import ForgotPassword from './components/auth/component/forgotPassword';
import Notification from './common/component/notification';
import Home from './components/home/container/home';
import SubscriptionPlan from './components/home/component/subcriptionPlan';

const App = props => {
  return (
    <div className='App'>
    <Notification />
      <Router>
        {
          props.auth && <Switch>
              <Route exact path='/' component={Home} />
              <Route exact path='/subscription/payment' component={Subscription} />
              <Route exact path='/subscription/plan' component={SubscriptionPlan} />
            <Redirect to='/' />
            </Switch>
        }
        {
          !props.auth && <Switch>
              <Route exact path='/login' component={Login} />
              <Route exact path='/signup' component={Signup} />
              <Route exact path='/forgot-password' component={ForgotPassword} />
              <Route exact path='/subscription' component={Subscription} />
              <Redirect to='/login' />
            </Switch>
        }
      </Router>
    </div>
  );
}

const mapStateToProps = state => ({
  auth: state.common.auth
});

export default connect(mapStateToProps)(App);
