import React, {useState} from 'react';
import Checkout from "../../payment/component/checkout";


const SubscriptionPayment = props => {
    const purchasePlans = props.purchasePlans;
    const allPlans = props.allPlans;
    const authData = props.authData;
    const excludePlan = allPlans.filter(({id: id1}) => !purchasePlans.some(({id: id2}) => id2 === id1));
    const [couponCode, setCouponCode] = useState("")
    const [subscription, setSubscription] = useState("")
    const product = allPlans.filter(({id: id1}) => {
        return id1 === subscription
    });

    return (
        <div className="subscribe">
            <h3 className=''>Subscription Details</h3>
            <div className="applyCoupon">
                <label>Apply Coupon</label>
                <div className='applyCouponSubDiv'>
                    <input placeholder='Enter Code Here' className="applyCouponTxt" type="text" name="couponCode"
                           value={couponCode}
                           onChange={(e) => setCouponCode(e.target.value)}/>
                    <button className="applyCouponBtn" onClick={() => props.applyCouponCode(couponCode)}>
                        Apply
                    </button>
                </div>
            </div>
            <div className="SubscriptionList">
                {allPlans.map(product => {
                    const matchProduct = obj => obj.id === product.id;
                    const disable = excludePlan.some(matchProduct)
                    return (
                        <div key={product.id} className={`sub-list-main-div px-20 ${disable ? 'checked' : ''}`}
                             onClick={() => {
                                 if (!disable) {
                                     setSubscription(product.id)
                                 }
                             }}>
                            <label className='plan-content'>
                                <input type='radio' name='subscription' disabled={disable}
                                       checked={subscription === product.id && !disable}
                                />
                                <div className="sub-list ">
                                    <span className="sub-title">{product.title}</span>
                                    <p className="sub-details">{product.description}</p>
                                </div>
                            </label>
                            <h4 className={'sub-price'}><span className='price-sign'>&#163;</span><span className='price'>{product.price}/<sub> Year</sub></span></h4>
                        </div>
                    );
                })}
            </div>
            {
                product && product.length === 1 &&
                <Checkout
                    productId={product[0].id}
                    couponCode={couponCode}
                    label={`Pay with stripe`}
                    email={authData.profile.email}
                    name='Test Routes'
                    description='Power by Stripe'
                    amount={product[0].price}
                    currency={product[0].currency}
                    stripePublishableKey={authData.config.stripeKey}
                    onSubmitPayment={props.handlePayment}
                />
            }

        </div>
    )
}

export default SubscriptionPayment;
