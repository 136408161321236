// const baseURL = 'http://35.177.122.209:3001/api';
const baseURL = 'https://api.testroutes.co.uk/api';
//const baseURL = 'http://localhost:3001/api';

export const GOOGLE_MAP_API_KEY = 'AIzaSyARGQgfVlVmfSrTBrg5Tt00pNxlCoccKmU';

export const API_CONFIG = {
    login: `${baseURL}/v1/user/login`,
    logout: `${baseURL}/v1/user/logout`,
    profile: `${baseURL}/v1/user/profile`,
    subscriptionProfile: `${baseURL}/v1/user/subscription/profile`,
    purchasePlans: `${baseURL}/v2/payment/products`,
    signup: `${baseURL}/v1/user/signup`,
    forgetPassword: `${baseURL}/v1/user/forget-password`,
    resetPassword: `${baseURL}/v1/user/reset-password`,
    traversal: `${baseURL}/v1/traversal`,
    payment: `${baseURL}/v2/payment/transact`,
    changePassword: `${baseURL}/v1/user/change-password`,
    verifyEmail: `${baseURL}/v1/user/email/verify`,
    couponCodeApply: `${baseURL}/v1/coupon/validate`
}
