import React, { Component } from 'react';
import { connect } from 'react-redux';
import Login from '../component/login';

import * as LoginActions from '../store/auth.action';

class LoginContainer extends Component {
    componentDidUpdate = () => {
        if (this.props.auth) {
            this.props.history.push('/');
        }
    }

    render() {        
        return (
            <Login
                loading={this.props.authLoading}
                login={this.props.login}
            />
        )
    }
}

const mapStateToProps = state => ({
    ...state.common
});

const mapDispatchToProps = dispatch => ({
    login: (data) => LoginActions.login(data, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);