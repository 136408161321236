import React from "react";
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import InputComponent from '../../../common/form/input';

const ChangePassword = props => {
    return (
        <Formik
            initialValues={{ oldPassword: "", confirmPassword: "", newPassword: "" }}
            onSubmit={props.onSubmit}
            validationSchema={ValidationSchema}
        >
            {({handleSubmit, values}) => (
                <form onSubmit={handleSubmit} className='change-password-wrapper'>
                    <h3 className=''>Change Password</h3>
                    <div className='form-item'>
                        <Field 
                            name='oldPassword'
                            type='password'
                            value={values.oldPassword}
                        >
                            {props => (<InputComponent 
                                    {...props}
                                    name='oldPassword'
                                    type='password'
                                    placeholder='Current Password'
                                />)}
                        </Field>
                        <ErrorMessage component='span' className='error' name='oldPassword' />
                    </div>
                    <div className='form-item'>
                        <Field 
                            name='newPassword'
                            type='password'
                            value={values.newPassword}
                        >
                            {props => (<InputComponent 
                                    {...props}
                                    name='newPassword'
                                    type='password'
                                    placeholder='New Password'
                                />)}
                        </Field>
                        <ErrorMessage component='span' className='error' name='newPassword' />
                    </div>
                    <div className='form-item'>
                        <Field 
                            name='confirmPassword'
                            type='password'
                            value={values.confirmPassword}
                        >
                            {props => (<InputComponent 
                                    {...props}
                                    name='confirmPassword'
                                    type='password'
                                    placeholder='Confirm Password'
                                />)}
                        </Field>
                        <ErrorMessage component='span' className='error' name='confirmPassword' />
                    </div>
                    <div className='text-center'>
                        <button disabled={props.loading} type='submit' className='button button--primary button-half-width'>Submit</button>
                    </div>
                </form>
            )}
        </Formik>
    );
}

const ValidationSchema = Yup.object({
    oldPassword: Yup.string().required('Please enter current password'),
    newPassword: Yup.string().required('Please enter new password'),
    confirmPassword: Yup.string().required('Please enter confirm password').test('password', 'Enter valid password', function (value) {
        return this.parent.newPassword === value
    }),
})

export default ChangePassword;